import React from 'react'
import { ButtonBase } from '@material-ui/core'

import { colors, alpha, s } from '../style/style'

const Button = ({ children, extraCSS }) => {
  return <ButtonBase css={[style, extraCSS]}>{children}</ButtonBase>
}

const style = {
  zIndex: 20,
  fontFamily: 'Spartan',
  fontSize: 1.125 + 'rem',
  [s.xs]: { fontSize: 1 + 'rem' },
  fontWeight: 700,
  color: colors.main.red,
  padding: '0.75rem 1.25rem',
  borderRadius: '2rem',
  backgroundColor: colors.main.red.concat(alpha[25]),
  transition:
    'background-color 0.15s ease-out, color 0.15s ease-out, padding 0.15s ease-out',
  '&:hover': {
    backgroundColor: colors.main.red,
    color: 'white',
    paddingRight: 0.75 + 'rem',
    svg: { paddingLeft: 0.5 + 'rem' },
  },
  span: {
    // lineHeight: 0.2,
    paddingRight: 0.5 + 'rem',
  },
  svg: {
    transition: 'padding 0.15s ease-out',
    opacity: 0.9,
  },
}

export default Button
