import React from 'react'

import Title from '../elements/Title'
import Subtitle from '../elements/Subtitle'

import { s, colors } from '../style/style'
import { graphql, useStaticQuery } from 'gatsby'
import { Grid } from '@material-ui/core'
import Img from 'gatsby-image'

import CameraIcon from '@material-ui/icons/Camera'
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera'
import VideocamIcon from '@material-ui/icons/Videocam'
import PanoramaIcon from '@material-ui/icons/Panorama'

// import drone_mavic from '../images/tech/drone_mavic.png'
// import drone_zoom from '../images/tech/drone_zoom.png'
// import drone_inspire from '../images/tech/drone_inspire.png'
// import drone_phantom from '../images/tech/drone_phantom.png'

import droneAdvanced from '../assets/droneAdvanced.svg'
import droneProfessional from '../assets/droneProfessional.svg'

const { text } = require('../content/content')

// const images = {
//   drone_mavic,
//   drone_phantom,
//   drone_inspire,
//   drone_zoom,
// }

const icons = {
  record: <VideocamIcon />,
  photo: <PhotoCameraIcon />,
  lens: <CameraIcon />,
  pros: <PanoramaIcon />,
}

const TechSection = () => {
  return (
    <div>
      <Title tag="h2">{text.tech.title}</Title>
      <Subtitle>{text.tech.field}</Subtitle>
      <TilesTech array={text.tech.drones} />
    </div>
  )
}

const TilesTech = ({ array }) => {
  const droneImages = useStaticQuery(graphql`
    query {
      drone_mavic: file(relativePath: { eq: "tech/drone_mavic.png" }) {
        childImageSharp {
          fluid(quality: 100, fit: CONTAIN, maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      drone_phantom: file(relativePath: { eq: "tech/drone_phantom.png" }) {
        childImageSharp {
          fluid(quality: 100, fit: CONTAIN, maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      drone_inspire: file(relativePath: { eq: "tech/drone_inspire.png" }) {
        childImageSharp {
          fluid(quality: 100, fit: CONTAIN, maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      drone_zoom: file(relativePath: { eq: "tech/drone_zoom.png" }) {
        childImageSharp {
          fluid(quality: 100, fit: CONTAIN, maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Grid
      container
      wrap="nowrap"
      justifyContent="flex-start"
      alignItems="center"
      css={TilesTechStyle.container}>
      {array &&
        array.map((tech, id) => (
          <TileTech
            key={id}
            tech={tech}
            imageSource={
              droneImages[tech.image] &&
              droneImages[tech.image].childImageSharp.fluid
            }
          />
        ))}
    </Grid>
  )
}

const TileTech = ({ tech, imageSource }) => (
  <div css={style.container}>
    {tech.tier === 1 && (
      <img className="tierSVG" src={droneProfessional} alt="Professional" />
    )}
    {tech.tier === 2 && (
      <img className="tierSVG" src={droneAdvanced} alt="Advanced" />
    )}
    <p className="title">{tech.name}</p>
    <div className="image">
      <Img fluid={imageSource} />
    </div>
    <div className="specs">
      {Object.entries(tech.specs).map(([type, value]) => (
        <div className="spec" key={value}>
          {icons[type]}
          <span>{value}</span>
        </div>
      ))}
    </div>
  </div>
)

const style = {
  container: {
    transition: 'transform 0.15s ease-out',
    '&:hover': {
      transform: 'scaleX(1.1) scaleY(1.1)',
      boxShadow:
        '0px 11px 15px -7px rgba(0,0,0,0.2), 0px 24px 38px 3px rgba(0,0,0,0.14), 0px 9px 46px 8px rgba(0,0,0,0.12)',
    },
    minWidth: 360 - 32,
    height: 480 - 32,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    borderRadius: '2rem',
    alignItems: 'center',
    backgroundColor: colors.main.dark,
    margin: ' 0 1rem',
    position: 'relative',
    '&:last-child()': {
      marginRight: '1rem',
    },
    [s.xs]: { minWidth: 300 - 32, height: 420 - 32 },
    '.title': { fontSize: 1 + 'rem', fontWeight: 700 },
    '.image': {
      height: 120,
      width: 200,
      maxWidth: 200,
      [s.xs]: { height: 80, width: 180, maxWidth: 180 },
    },
    '.specs': {
      fontSize: 0.675 + 'rem',
      marginBottom: 1 + 'rem',
      [s.xs]: { marginBottom: 2 + 'rem' },
      zIndex: 6,
    },
    '.spec': {
      opacity: 0.5,
      marginBottom: 0.5 + 'rem',
      display: 'flex',
      alignItems: 'center',
      [s.xs]: { marginBottom: 0.25 + 'rem' },
      span: {
        paddingLeft: 1 + 'rem',
      },
    },
    '.tierSVG': {
      width: 100,
      height: 100,
      zIndex: 5,
      position: 'absolute',
      bottom: 0,
      right: 0,
      [s.xs]: { width: 80, height: 80 },
    },
  },
  active: {
    transform: 'scaleX(1.1) scaleY(1.1)',
  },
  disactive: {
    opacity: 0.3,
  },
  non: {},
}

const TilesTechStyle = {
  container: {
    '::-webkit-scrollbar': {
      width: 8,
      height: 8,
    },

    [s.xs]: {
      '::-webkit-scrollbar-thumb': {
        background: 'rgba(255, 255, 255, 0.36)',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: 'rgba(255, 255, 255, 0.60)',
      },
    },

    [s.sm]: {
      '::-webkit-scrollbar-thumb': {
        background: 'rgba(255, 255, 255, 0.12)',
      },
    },
    [s.md]: {
      '::-webkit-scrollbar': {
        width: 0,
        height: 0,
      },
    },
    boxSizing: 'border-box',
    overflowX: 'scroll',
    WebkitOverflowScrolling: 'touch',
    [s.xs]: { padding: '2rem 1rem 2rem 0.5rem' },
    [s.sm]: { padding: `4rem calc(10vw)` },
    margin: 0,
    // backgroundColor: 'rgba(255, 0, 0, 0.1)',
    // height: 600,
    // minWidth: '100vw',
    [s.lg]: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
}

export default TechSection
