import React from 'react'

import Title from '../elements/Title'
import Subtitle from '../elements/Subtitle'
import Tiles from '../elements/Tiles'
// import ScrollButton from '../elements/ScrollButton'

const { text } = require('../content/content')

const ServicesSection = () => {
  return (
    <div>
      <Title tag="h2">{text.services.title}</Title>
      <Subtitle>{text.services.field}</Subtitle>
      <Tiles array={text.services.tiles} page="services" />
      {/* <ScrollButton /> */}
    </div>
  )
}

export default ServicesSection
