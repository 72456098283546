import React from 'react'
import { Link } from 'gatsby'

import serviceHire from '../assets/serviceHire.svg'
import serviceVideo from '../assets/serviceVideo.svg'
import serviceShooting from '../assets/serviceShooting.svg'
import serviceEvent from '../assets/serviceEvent.svg'
import serviceInvestigation from '../assets/serviceInvestigation.svg'

import Button from './Button'
import ForwardIcon from '@material-ui/icons/Forward'

import { colors, s } from '../style/style'

const icons = {
  serviceHire,
  serviceVideo,
  serviceShooting,
  serviceEvent,
  serviceInvestigation,
}

const TileServices = ({ tile, even }) => {
  return (
    <div css={style.conatiner} id={tile.icon}>
      <div className={`topPart ${even ? 'evenTile' : 'oddTile'}`}>
        <img
          className="icon"
          id={`${tile.icon}-icon`}
          src={icons[tile.icon]}
          alt="alt"
          css={{ zIndex: 5 }}
        />

        <div className="text">

          {tile.title.map((word, id) => (
                    <Link to={tile.link}>
            <p key={id} id={`${tile.icon}${id}`}>
              {word}
            </p>
            </Link>
          ))}
        </div>
      </div>
      <div className="field">{tile.field}</div>
      <Link to={tile.button.link}>
        <Button extraCSS={style.btn}>
          <span>{tile.button.title}</span>
          <ForwardIcon />
        </Button>
      </Link>
    </div>
  )
}

const style = {
  conatiner: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    '#serviceVideo-icon': {
      paddingTop: 50,
      height: 250,
      width: 'auto',
      [s.xs]: { height: 200, paddingTop: 0 },
    },
    '#serviceInvestigation-icon': {
      paddingTop: 0,
      left:0 +'rem',
      top:-95,
      height: 380,
      width: 'auto',
      [s.xs]: { height: 250, paddingTop: 0,top:-47, },
    },
    '.icon': {
      // backgroundColor: 'green',
      zIndex: 5,
      height: 300,
      width: 'auto',
      position: 'absolute',
      top: -32,
      left: 2 + 'rem',
      [s.xs]: { height: 250 },
    },
    '.topPart': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '.text': {
      textAlign: 'center',
      zIndex: 7,
      letterSpacing: '-2px',
      marginTop: '4rem',
      [s.xs]: { marginTop: '3rem' },
      marginLeft: '6rem',
      marginBottom: '2rem',
      lineHeight: '0.9',
      color: colors.main.red,
      fontWeight: 700,
      '& p:nth-of-type(3)': {
        marginTop: 2 + 'rem',
      },
    },
    '#serviceHire0, #serviceHire2, #serviceVideo1, #serviceVideo2, #serviceShooting1, #serviceShooting2, #serviceEvent1, #serviceEvent2, #serviceInvestigation1, #serviceInvestigation2': {
      [s.xs]: { fontSize: 24 },
      // [s.sss]: { fontSize: 32 },
      [s.sm]: { fontSize: 36 },
      [s.md]: { fontSize: 32 },
    },
    '#serviceHire1, #serviceHire3, #serviceVideo0, #serviceVideo3, #serviceShooting0, #serviceShooting3, #serviceEvent0, #serviceEvent3, #serviceInvestigation0, #serviceInvestigation3': {
      [s.xs]: { fontSize: 36 },
      // [s.sss]: { fontSize: 48 },
      [s.sm]: { fontSize: 48 },
      [s.md]: { fontSize: 64 },
    },
    '.evenTile': { textShadow: `2px 2px 0px white` },
    '.oddTile': { textShadow: `2px 2px 0px ${colors.main.tile}` },
    '.field': {
      // overflow: 'scroll',
      minHeight: 180,
      maxHeight: 180,
      fontSize: 0.875 + 'rem',
      lineHeight: 1.618 + 'em',
      [s.xs]: {
        padding: '1rem 2rem 2rem',
        fontSize: 0.75 + 'rem',
        minHeight: 120,
        maxHeight: 120,
        // backgroundColor: 'blue',
      },
      [s.sss]: { minHeight: 450, maxHeight: 500 },
      [s.xs]: { padding: '2rem 2rem 0rem',minHeight: 380, maxHeight: 400 },
      [s.sm]: { padding: '2rem 2rem 0rem',minHeight: 350, maxHeight: 370  },
      [s.md]: { padding: '2rem 2rem 0rem',minHeight: 300, maxHeight: 320 },
      [s.lg]: { padding: '2rem 4rem 0rem',minHeight: 270, maxHeight: 300 },
      [s.xl]: { padding: '2rem 6rem 0rem', minHeight: 250, maxHeight: 300 },
    },
  },
  btn: {
    margin: '0rem 0 4rem',
  },
}

export default TileServices
