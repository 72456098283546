import React from 'react'
import { Link } from 'gatsby'

import Button from './Button'
import ForwardIcon from '@material-ui/icons/Forward'
import DoneIcon from '@material-ui/icons/Done'

import { colors, s } from '../style/style'

const TileCourses = ({ tile, even }) => {
  return (
    <div css={style.conatiner} id={tile.icon}>
      <div>
        <div css={style.title}>
          {tile.title.map((title, id) => (
            <p key={id} className={`title${id}`}>
              {title}
            </p>
          ))}
        </div>
        <div css={style.description}>
          {tile.description.map((para, id) => (
            <p key={id}>{para}</p>
          ))}
        </div>

        <div css={style.pros}>
          {tile.pros.map((pro, id) => (
            <p key={id}>
              <DoneIcon fontSize="small" css={{ paddingRight: 0.5 + 'rem' }} />
              {pro}
            </p>
          ))}
        </div>
      </div>
      <div css={style.linkButton}>
        {tile.button.external ? (
          <a href={tile.button.link} target="_blank" rel="noreferrer">
            <Button>
              <span>{tile.button.title}</span>
              <ForwardIcon />
            </Button>
          </a>
        ) : (
          <Link to={tile.button.link}>
            <Button extraCSS="">
              <span>{tile.button.title}</span>
              <ForwardIcon />
            </Button>
          </Link>
        )}
      </div>
    </div>
  )
}

const style = {
  conatiner: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    [s.xs]: { padding: '2.5rem 2rem 0' },
    [s.sm]: { padding: '4rem 2rem 0' },
    [s.md]: { padding: '6rem 4rem 0' },
  },
  title: {
    letterSpacing: '-2px',
    color: colors.main.red,
    paddingBottom: '1rem',
    // [s.xs]: {paddingBottom: 0},
    lineHeight: 1,
    fontWeight: 700,
    textAlign: 'right',
    '.title0': {
      [s.xs]: { fontSize: 1.5 + 'rem' },
      fontSize: 1.5 + 'rem',
      [s.lg]: { fontSize: 2 + 'rem' },
    },
    '.title1': {
      [s.xs]: { fontSize: 2.25 + 'rem' },
      fontSize: 2.5 + 'rem',
      [s.lg]: { fontSize: 3.25 + 'rem' },
      [s.xl]: { fontSize: 4 + 'rem' },
    },
  },

  description: {
    // overflow: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: 250,
    maxHeight: 250,
    paddingBottom: '1rem',
    lineHeight: 1.618 + 'em',
    fontSize: 0.875 + 'rem',
    [s.xs]: {
      paddingTop: '0.5rem',
      paddingBottom: '1.5rem',
      fontSize: 0.75 + 'rem',
      minHeight: 150,
      maxHeight: 150,
      // backgroundColor: 'blue',
      justifyContent: 'flex-start',
    },
    [s.sss]: {
      minHeight: 170,
      maxHeight: 170,
    },
  },
  pros: {
    paddingBottom: '1rem',
    minHeight: 150,
    maxHeight: 150,
    fontSize: 0.875 + 'rem',
    fontWeight: 700,
    p: { marginBottom: 0.5 + 'rem' },
    [s.xs]: {
      fontSize: 0.75 + 'rem',
      minHeight: 120,
      maxHeight: 120,
      // backgroundColor: 'green',
      paddingBottom: '0rem',
      p: { marginBottom: 0.25 + 'rem' },
    },
  },
  linkButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '4rem',
  },
}

export default TileCourses
