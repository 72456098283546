import React from 'react'

import Title from '../elements/Title'
import Subtitle from '../elements/Subtitle'
import Tiles from '../elements/Tiles'
// import ScrollButton from '../elements/ScrollButton'

const { text } = require('../content/content')

const CoursesSection = () => {
  return (
    <div>
      <Title tag="h2">{text.courses.title}</Title>
      <Subtitle>{text.courses.field}</Subtitle>
      <Tiles array={text.courses.options} page="courses" />
      {/* <ScrollButton /> */}
    </div>
  )
}

export default CoursesSection
