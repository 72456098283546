import React from 'react'
import { colors, s } from '../style/style'

const VideoList = ({ handleVideoSelect, videos, selectedVideo }) => {
  const renderVideos = videos.map((video, id) => (
    <VideoItem
      key={id}
      video={video}
      selectedVideo={selectedVideo}
      handleVideoSelect={handleVideoSelect}
    />
  ))
  return <div css={style.videoListContainer}>{renderVideos}</div>
}

const VideoItem = ({ video, handleVideoSelect, selectedVideo }) => {
  return (
    <div
      onClick={() => handleVideoSelect(video)}
      onKeyPress={() => handleVideoSelect(video)}
      tabIndex="0"
      role="button"
      css={[
        style.videoItem,
        video &&
          selectedVideo &&
          video.id === selectedVideo.id &&
          style.selectedVideo,
      ]}>
      <img
        src={video.snippet.thumbnails.medium.url}
        alt={video.snippet.title}
      />
    </div>
  )
}

const style = {
  videoListContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    overflowX: 'auto',
    WebkitOverflowScrolling: 'touch',
    marginBottom: '4rem',
    '::-webkit-scrollbar': {
      width: 8,
      height: 8,
    },
    [s.xs]: {
      '::-webkit-scrollbar': {
        width: 0,
        height: 0,
      },
    },
    [s.sm]: { marginLeft: '10vw', width: 90 / 1.25 + 'vw' },
    [s.lg]: { width: 90 / 2 + 'vw' },
  },
  videoItem: {
    marginBottom: '0.5rem',
    cursor: 'pointer',
    transition: 'border-color 0.2s ease-out',
    borderBottom: `4px solid transparent`,
    '&:hover': {
      img: { filter: 'grayscale(1)' },
    },
    img: {
      transition: 'filter 0.2s ease-out',
      [s.xs]: { width: 180, height: 'auto' },
    },
  },
  selectedVideo: {
    borderBottom: `4px solid ${colors.main.red}`,
  },
}

export default VideoList
