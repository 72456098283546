import React from 'react'
// import Loader from '../elements/Loader'

// const Sections = lazy(() => import('../sections/_sections'))
// const VideoIndex = lazy(() => import('../elements/VideoIndex'))

import Sections from '../sections/_sections'
import VideoIndex from '../elements/VideoIndex'

const StartPage = () => {
  return (
    // <Suspense fallback={<Loader extraCSS={styleLoader} />}>
    <div>
      <VideoIndex />
      <Sections />
    </div>
    // </Suspense>
  )
}

// const styleLoader = {
//   height: 'calc(100vh + 100px)',
//   marginTop: `-100px`,
//   div: {
//     width: 100,
//     height: 100,
//   },
// }

export default StartPage
