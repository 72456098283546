import React, { useState } from 'react'

import { Hidden } from '@material-ui/core'
import { colors, s } from '../style/style'

const Subtitle = ({ children, tag = 'body1', maxLength = 255 }) => {
  const [extended, setExtend] = useState(null)

  return (
    <div>
      {children.map((para, id) => (
        <React.Fragment key={id}>
          <Hidden lgUp>
            <p css={style}>
              {para.length >= maxLength ? (
                <>
                  {para.slice(0, maxLength)}
                  <span css={[visibleSpan, extended !== id && hiddenSpan]}>
                    {para.slice(maxLength)}
                  </span>
                  {extended === id ? (
                    <span
                      className="readmore"
                      onClick={() => setExtend(null)}
                      onKeyPress={() => setExtend(null)}
                      role="button"
                      tabIndex="0">
                      {' '}
                      ukryj
                    </span>
                  ) : (
                    <span
                      className="readmore"
                      onClick={() => setExtend(id)}
                      onKeyPress={() => setExtend(id)}
                      role="button"
                      tabIndex="0">
                      {' '}
                      ...czytaj więcej
                    </span>
                  )}
                </>
              ) : (
                para
              )}
            </p>
          </Hidden>
          <Hidden mdDown>
            <p css={style}>{para}</p>
          </Hidden>
        </React.Fragment>
      ))}
    </div>
  )
}

const style = {
  lineHeight: '1.618em',
  fontFamily:
    '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Helvetica Neue", Arial, sans-serif',
  color: 'rgba(255, 255, 255, 0.75)',
  [s.xs]: {
    // fontSize: 0.875 + 'rem',
    fontSize: 1 + 'rem',
    marginBottom: 2 + 'rem',
    padding: '0 2rem',
    maxWidth: '100%',
    fontWeight: 400,
  },
  [s.sm]: {
    fontSize: 1 + 'rem',
    marginBottom: 2 + 'rem',
    padding: '0 10vw',
    maxWidth: '75%',
    fontWeight: 300,
  },
  [s.md]: {
    fontSize: 1 + 'rem',
    marginBottom: 2 + 'rem',
    maxWidth: '60%',
  },
  [s.xl]: {
    fontSize: 1.125 + 'rem',
    maxWidth: '50%',
  },
  '.readmore': {
    fontWeight: 700,
    whiteSpace: 'nowrap',
    wordBreak: 'keep-all',
    color: colors.text.blue,
    cursor: 'pointer',
    '&:hover': { color: 'white' },
  },
}

const visibleSpan = {
  transition: 'opacity 0.6s ease-out',

  // whiteSpace: 'nowrap',
  overflow: 'hidden',
  // textOverflow: 'ellipsis',
}

const hiddenSpan = {
  display: 'inline-block',
  height: 0,
  width: 0,
  lineHeight: 0,
  opacity: 0,
  pointerEvents: 'none',
}

export default Subtitle
