import React from 'react'
import YouTubeIcon from '@material-ui/icons/YouTube'

import { colors, s, alpha } from '../style/style'

const VideoContainer = ({ video, videos, handleVideoSelect }) => {
  const videoSrc =
    video &&
    `https://www.youtube.com/embed/${video.snippet.resourceId.videoId}?autoplay=0&rel=0`

  return (
    <div css={style.container}>
      <div css={style.videoContainer}>
        {video ? (
          <iframe
            css={style.videoFrame}
            src={videoSrc}
            title={video.snippet.title}
            frameBorder="0"
            allowFullScreen="allowfullscreen"
            mozallowfullscreen="mozallowfullscreen"
            msallowfullscreen="msallowfullscreen"
            oallowfullscreen="oallowfullscreen"
            webkitallowfullscreen="webkitallowfullscreen"
          />
        ) : (
          <div
            css={style.loaderContainer}
            onClick={() => handleVideoSelect(videos?.[0])}
            onKeyPress={() => handleVideoSelect(videos?.[0])}
            role="button"
            tabIndex="0">
            <YouTubeIcon fontSize="large" />
            <p>Wybierz video</p>
          </div>
        )}
      </div>
    </div>
  )
}

const style = {
  container: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    [s.md]: { marginBottom: '2rem' },
  },
  videoContainer: {
    transition: 'background-color 0.2s ease-out',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.main.dark,
    '&:hover': {
      backgroundColor: colors.main.light,
    },
    [s.xs]: {
      width: '100vw',
      height: 900 / 16 + 'vw',
    },
    [s.sm]: {
      marginLeft: '10vw',
      width: 90 / 1.25 + 'vw',
      height: (90 * 9) / 16 / 1.25 + 'vw',
      maxWidth: '90vw',
    },
    [s.lg]: {
      marginLeft: '10vw',
      width: 90 / 2 + 'vw',
      height: (90 * 9) / 16 / 2 + 'vw',
    },
  },
  loaderContainer: {
    cursor: 'pointer',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    p: {
      paddingTop: 1 + 'rem',
      fontSize: 0.875 + 'rem',
      color: colors.main.red,
      fontWeight: 700,
    },
    'div svg, svg': {
      color: colors.main.red,
    },
  },
  videoFrame: {
    width: '100%',
    height: '100%',
    border: 'none',
    boxShadow: `0px 0px 12px 12px ${'#000000'.concat(alpha[15])}`,
  },
}

export default VideoContainer
