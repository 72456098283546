import React, { useEffect, useState } from 'react'
import { s } from '../style/style'

const video_url = '427683940'

const VideoVimeo = ({ id }) => {
  const [videoHTML, setVideoHTML] = useState(null)

  const handleLoad = async () => {
    const response = await fetch(
      `https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/${video_url}&autoplay=true&loop=true&muted=false&responsive=true`
    )

    const data = await response.json()
    setVideoHTML(data.html)
  }

  useEffect(() => {
    handleLoad()
  }, [])

  return (
    <div css={style.vimeoContainer}>
      <div dangerouslySetInnerHTML={{ __html: videoHTML }} />
    </div>
  )
}

const style = {
  vimeoContainer: {
    width: '100%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    [s.xs]: { maxWidth: '100vw', maxHeight: '100vh' },
    [s.sm]: { maxWidth: '100vw', maxHeight: '100vh' },
    [s.lg]: { maxWidth: '75vw', maxHeight: '75vh' },
    [s.xl]: { maxWidth: '50vw', maxHeight: '50vh' },
  },
}

export default VideoVimeo
