import React from 'react'

import { Grid } from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

import { alpha } from '../style/style'

const { text } = require('../content/content')

const ScrollButton = ({ container }) => {
  const scrollContainer = value => {
    container.current.scrollTo({ top: 0, left: value, behavior: 'smooth' })
  }

  return (
    <Grid container justifyContent="center" css={style.scrollContainer}>
      <Grid item css={style.scrollInner}>
        <div
          onClick={() =>
            scrollContainer(container.current.scrollLeft - window.innerWidth)
          }
          onKeyPress={() =>
            scrollContainer(container.current.scrollLeft - window.innerWidth)
          }
          role="button"
          tabIndex="0">
          <ArrowBackIosIcon />
        </div>
        <span
          onClick={() =>
            scrollContainer(container.current.scrollLeft + window.innerWidth)
          }
          onKeyPress={() =>
            scrollContainer(container.current.scrollLeft + window.innerWidth)
          }
          css={style.scroll}
          role="button"
          tabIndex="0">
          {text.services.scroll}
        </span>
        <div
          onClick={() =>
            scrollContainer(container.current.scrollLeft + window.innerWidth)
          }
          onKeyPress={() =>
            scrollContainer(container.current.scrollLeft + window.innerWidth)
          }
          role="button"
          tabIndex="0">
          <ArrowForwardIosIcon />
        </div>
      </Grid>
    </Grid>
  )
}

const style = {
  scrollInner: {
    transition: 'background-color 0.2s ease-out',
    padding: '0.5rem 1rem',
    borderRadius: 2 + 'rem',
    '&:hover': {
      backgroundColor: '#ffffff'.concat(alpha[10]),
      span: {
        opacity: 1,
      },
      svg: {
        opacity: 0.5,
      },
    },
  },
  scrollContainer: {
    marginTop: '2rem',
    fontWeight: 700,
    fontSize: 0.875 + 'rem',
    div: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      borderRadius: 2 + 'rem',
    },
    'span, svg': {
      transition: 'opacity 0.2s ease-out',
      '&:hover': {
        opacity: '1 !important',
      },
    },
    span: {
      padding: '0 0.5rem',
      opacity: 0.5,
      cursor: 'pointer',
    },
    svg: {
      color: 'white',
      opacity: 0.25,
    },
  },
}

export default ScrollButton
