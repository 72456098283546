import React from 'react'
import { Typography } from '@material-ui/core'

import { colors, s } from '../style/style'

const Title = ({ children, tag = 'h6' }) => (
  <Typography component={tag} css={style}>
    {children}
  </Typography>
)

const style = {
  fontFamily: 'Spartan',
  fontWeight: 700,
  color: 'white',
  [s.xs]: {
    fontSize: 1.375 + 'rem',
    marginBottom: 2 + 'rem',
    marginLeft: 2 + 'rem',
    marginRight: 2 + 'rem',
    textShadow: `2px 2px 0px ${colors.main.red}`,
  },
  [s.sm]: {
    fontSize: 1.375 + 'rem',
    marginBottom: 2 + 'rem',
    marginLeft: '10vw',
    textShadow: `-3px 3px 0px ${colors.main.red}`,
  },
  [s.md]: {
    fontSize: 2 + 'rem',
    marginBottom: 2 + 'rem',
  },
  [s.lg]: {
    fontSize: 2.5 + 'rem',
    // marginBottom: 1 + 'rem',
  },
  [s.xl]: {
    fontSize: 3 + 'rem',
    // marginBottom: 1 + 'rem',
  },
}

export default Title
