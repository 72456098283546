import React from 'react'

import { s } from '../style/style'

// Sections
// const AboutSection = lazy(() => import('./AboutSection'))
// const ContactSection = lazy(() => import('./ContactSection'))
// const CoursesSection = lazy(() => import('./CoursesSection'))
// const PortfolioSection = lazy(() => import('./PortfolioSection'))
// const ServicesSection = lazy(() => import('./ServicesSection'))
// const StartSection = lazy(() => import('./StartSection'))
// const TechSection = lazy(() => import('./TechSection'))

import AboutSection from './AboutSection'
import ContactSection from './ContactSection'
import CoursesSection from './CoursesSection'
import PortfolioSection from './PortfolioSection'
import ServicesSection from './ServicesSection'
import ServicePage from './ServiceSection'
import StartSection from './StartSection'
import TechSection from './TechSection'
import WynajemSection from './WynajemSection'
import ShootingSection from './ShootingSection'
import EventSection from './EventSection'
import VideoSection from './VideoSection'

const Sections = {
  AboutSection,
  ContactSection,
  CoursesSection,
  PortfolioSection,
  ServicesSection,
  ServicePage,
  StartSection,
  TechSection,
  WynajemSection,
  ShootingSection,
  EventSection,
  VideoSection,
}

const { pages } = require('../content/content.js')

const _sections = () => {
  const renderSections = () => {
    return Object.entries(pages)
      .filter(([key, page]) => page.indexId)
      .sort((a, b) => a[1].indexId - b[1].indexId)
      .map(([key, section], id) => {
        let componentName = `${
          key.charAt(0).toUpperCase() + key.slice(1)
        }Section`
        return typeof Sections[componentName] !== 'undefined' ? (
          <section key={id} id={section.name} css={style}>
            {React.createElement(Sections[componentName])}
          </section>
        ) : (
          React.createElement(
            () => <div>Section: {componentName} nie została stworzona</div>,
            { key: id }
          )
        )
      })
  }

  return renderSections()
}

const style = {
  [s.xs]: { marginBottom: 6 + 'rem' },
  [s.sss]: { marginBottom: 4 + 'rem' },
  [s.sm]: { marginBottom: 6 + 'rem' },
  [s.md]: { marginBottom: 8 + 'rem' },
  [s.lg]: { marginBottom: 10 + 'rem' },
}

export default _sections
