import React from 'react'

import { s } from '../style/style'

const TextContainer = ({ text, extraCSS, startPage }) => {
  const textContainer = {
    textTransform: 'uppercase',
    fontFamily:
      '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Helvetica Neue", Arial, sans-serif',
    position: 'absolute',
    top: '50%',
    [s.lg]: { top: '60%' },
    left: '50%',
    textAlign: 'center',
    transform: `translate(-50%, -50%)`,
    width: '100%',
    fontSize: 6 + 'rem',
    fontWeight: 700,
    wordSpacing: '24px',
    textShadow: '4px 8px 10px rgba(0,0,0,0.5)',
    [s.xs]: {
      fontSize: 3 + 'rem',
      letterSpacing: '-2px',
      opacity: 1,
      wordSpacing: 'default',
    },
    [s.sss]: {
      fontSize: 2.5 + 'rem',
      letterSpacing: '-2px',
    },
    [s.sm]: {
      fontSize: 4 + 'rem',
      letterSpacing: '-6px',
      opacity: 0.75,
    },
    [s.md]: { fontSize: 5 + 'rem' },
    [s.lg]: { fontSize: 6 + 'rem', letterSpacing: '-8px' },
    h1: {
      fontSize: 'inherit',
    },
    spanElement: {
      willChange: 'opacity, transform',
      fontSize: 'inherit',
      animation: 'text-pop-up-top 3.2s ease-out both',
      '@keyframes text-pop-up-top': {
        '0%': {
          opacity: 0,
        },
        '25%': {
          opacity: 1,
        },
        '75%': {
          opacity: 1,
        },
        '100%': {
          opacity: startPage ? 0 : 1,
        },
      },
    },
  }
  return (
    <div css={[textContainer, extraCSS]}>
      {text && typeof text === 'object' ? (
        <h1>
          {text.map((element, id) => (
            <span
              key={id}
              css={[
                textContainer.spanElement,
                { animationDelay: (0.2 + 0.2 * id) * (id / 2 + 1) + 0.5 + 's' },
              ]}>
              {element}
            </span>
          ))}
        </h1>
      ) : (
        <h1>{text}</h1>
      )}
    </div>
  )
}

export default TextContainer
