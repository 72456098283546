import React from 'react'
import Title from '../elements/Title'
import Subtitle from '../elements/Subtitle'
import serviceHire from '../assets/serviceHire.svg'
import { s } from '../style/style'
import Button from '../elements/Button'
import { Link } from 'gatsby'

const { text } = require('../content/content')

const WynajemSection = () => {
  return (
    <section>
      <Title tag="h2">Wynajem Drona!</Title>
      <div css={style.flexBox}>
        <div css={style.txtBox}>
          <div>
            <Subtitle>
              <p>
                Pellentesque habitant morbi tristique senectus et netus et
                malesuada fames ac turpis egestas. Vestibulum tortor quam,
                feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu
                libero sit amet quam egestas semper. Aenean ultricies mi vitae
                est. Mauris placerat eleifend leo.
              </p>
              <p>
                Pellentesque habitant morbi tristique senectus et netus et
                malesuada fames ac turpis egestas.
              </p>
              <p>
                Pellentesque habitant morbi tristique senectus et netus et
                malesuada fames ac turpis egestas. Vestibulum tortor quam,
                feugiat vitae, ultricies eget, tempor sit amet, ante. Donec eu
                libero sit amet quam egestas semper. Aenean ultricies mi vitae
                est. Mauris placerat eleifend leo. Quisque sit amet est et
                sapien ullamcorper pharetra. Vestibulum erat wisi, condimentum
                sed, commodo vitae, ornare sit amet, wisi. Aenean fermentum,
                elit eget tincidunt condimentum, eros ipsum rutrum orci,
                sagittis tempus lacus enim ac dui. Donec non enim in turpis
                pulvinar facilisis. Ut felis. Praesent dapibus, neque id cursus
                faucibus, tortor neque egestas augue, eu vulputate magna eros eu
                erat. Aliquam erat volutpat. Nam dui mi, tincidunt quis,
                accumsan porttitor, facilisis luctus, metus
              </p>
            </Subtitle>
            <div css={style.button}>
              <Link to={text.wynajem.link}>
                <Button extraCSS={style.openPlayerButton}>
                  <span>ZAMÓW !</span>
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <div css={style.hiding}>
          <div css={style.imgPosition}>
            <img css={style.imgBox} src={serviceHire} alt="" />
          </div>
        </div>
      </div>
    </section>
  )
}
const style = {
  imgBox: {
    height: '110%',
    width: '100%',
  },
  imgPosition: {
    position: 'relative',
    right: 100,
  },
  txtBox: {
    width: '70%',
    ' @media only screen and (max-width: 980px)': {
      width: '100%',
      p: {
        maxWidth: '100% !important',
      },
    },
  },
  flexBox: {
    display: 'flex',
    flexDirection: 'row',
  },
  hiding: {
    ' @media only screen and (max-width: 980px)': {
      display: 'none',
    },
  },
  openPlayerButton: {
    width: 200,
    position: 'relative',
    fontSize: 0.875 + 'rem',
    color: 'white',
    backgroundColor: 'transparent',
    border: '2px solid rgba(255,255,255,0.5)',
    '&:hover': {
      borderColor: 'transparent',
      paddingLeft: 0.75 + 'rem',
    },
    [s.xs]: { fontSize: 0.75 + 'rem', width: 240 },
    ' @media only screen and (max-width: 980px)': {
      margin: '0 auto',
      width: '200px',
    },
    // ' @media only screen and (max-width: 980px)':{
    //   margin:'0 auto',
    //  }
  },
  button: {
    width: '73%',
    margin: 'auto',
    ' @media only screen and (max-width: 980px)': {
      margin: '0 auto',
      width: '200px',
    },
    [s.xs]: { fontSize: 0.75 + 'rem', width: 240 },
  },
}
export default WynajemSection
