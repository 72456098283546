import React from 'react'

import Button from '../elements/Button'

import CircularProgress from '@material-ui/core/CircularProgress'
import BlockIcon from '@material-ui/icons/Block'
import SendIcon from '@material-ui/icons/Send'

import { colors, alpha } from '../style/style'

const { text } = require('../content/content')

const SendFormButton = ({ mailing, onFormSubmit, type }) => {
  if (mailing === 'sending')
    return (
      <div css={style.loaderContainer}>
        <CircularProgress />
      </div>
    )
  else if (mailing === 'error')
    return (
      <div>
        <Button
          extraCSS={{
            backgroundColor: colors.main.darkRed,
            color: colors.main.lightRed,
          }}>
          <span>Wypełnij pola!</span>
          <BlockIcon />
        </Button>
      </div>
    )
  else
    return (
      <div
        onClick={() => {
          onFormSubmit(type)
        }}
        onKeyPress={() => {
          onFormSubmit(type)
        }}
        role="button"
        tabIndex="0">
        <Button extraCSS={style.sendButton}>
          <span>{text.contact.form.button[type]}</span>
          <SendIcon />
        </Button>
      </div>
    )
}

const style = {
  loaderContainer: {
    width: 48,
    height: 48,
    display: 'flex',
    alignItems: 'center',
    'div svg': {
      color: colors.main.red,
    },
  },
  sendButton: {
    backgroundColor: '#666666',
    color: '#ffffff'.concat(alpha[75]),
  },
}

export default SendFormButton
