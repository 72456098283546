import React from 'react'

import Title from '../elements/Title'
import Subtitle from '../elements/Subtitle'

const { text } = require('../content/content')

const AboutSection = () => {
  return (
    <div>
      <Title tag="h2">{text.about.title}</Title>
      <Subtitle>{text.about.field}</Subtitle>
    </div>
  )
}

export default AboutSection
