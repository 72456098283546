import React, { useRef } from 'react'
import { Grid, Hidden } from '@material-ui/core'
import { s, colors } from '../style/style'
import triangle2 from '../assets/triangle2'

// const TileServices = lazy(() => import('./TileServices'))
// const TileCourses = lazy(() => import('./TileCourses'))
// const ScrollButton = lazy(() => import('./ScrollButton'))

import TileServices from './TileServices'
import TileCourses from './TileCourses'
import ScrollButton from './ScrollButton'

const Tiles = ({ array, page }) => {
  const refContainer = useRef(null)
  return (
    <div>
      <Grid
        container
        wrap="nowrap"
        alignItems="center"
        ref={refContainer}
        css={[TileStyle.container, page === 'courses' && overflowOffOnDesktop]}>
        {array &&
          array.map((tile, id) => (
            <Tile
              key={id}
              tile={tile}
              even={Boolean((id + 2) % 2 === 0)}
              page={page}
            />
          ))}
      </Grid>
      {page === 'courses' ? (
        <Hidden lgUp>
          <ScrollButton container={refContainer} />
        </Hidden>
      ) : (
        <ScrollButton container={refContainer} />
      )}
    </div>
  )
}

const Tile = ({ even, tile, page }) => {
  return (
    <div
      css={[
        OneTileStyle.container,
        even ? OneTileStyle.even : OneTileStyle.odd,
      ]}>
      <div className="triangleTop">{triangle2}</div>
      <div className="triangleBot">{triangle2}</div>
      {page === 'services' && <TileServices tile={tile} even={even} />}
      {page === 'courses' && <TileCourses tile={tile} />}
    </div>
  )
}

const TileStyle = {
  container: {
    '::-webkit-scrollbar': {
      width: 8,
      height: 8,
    },

    [s.xs]: {
      '::-webkit-scrollbar-thumb': {
        background: 'rgba(255, 255, 255, 0.36)',
      },
      '::-webkit-scrollbar-thumb:hover': {
        background: 'rgba(255, 255, 255, 0.60)',
      },
    },

    [s.sm]: {
      '::-webkit-scrollbar-thumb': {
        background: 'rgba(255, 255, 255, 0.12)',
      },
    },

    // backgroundColor: 'red',
    overflowX: 'auto',
    WebkitOverflowScrolling: 'touch',
  },
}

const OneTileStyle = {
  container: {
    marginTop: '6rem',
    // overflow: 'scroll',
    position: 'relative',
    color: colors.main.background,
    boxShadow: `inset 0px -2px 0px 0px ${colors.main.background}`,
    // maxHeight: 'min-content',
    [s.xs]: {
      minWidth: '100vw',
      minHeight: '60vh',
      marginBottom: 1 + 'rem',
      // maxHeight: '80vh'
    },
    [s.sm]: {
      minWidth: '50vw',
      minHeight: '60vh',
      marginBottom: 1 + 'rem',
      // maxHeight: '75vh'
    },
    [s.md]: {
      minWidth: '50vw',
      minHeight: '60vh',
      // maxHeight: '60vh'
    },
    [s.lg]: {
      minWidth: '33.3vw',
      minHeight: '50vh',
      // maxHeight: '60vh'
      marginTop: '8rem',
    },
    '.triangleTop, .triangleBot': {
      zIndex: 3,
      svg: {
        zIndex: 3,
        maxWidth: '100.5%',
        height: 'auto',
        position: 'absolute',
        path: { fill: colors.main.background, zIndex: 3 },
      },
    },
    '.triangleTop svg': { top: 0, left: 0 },
    '.triangleBot svg': { bottom: 0, right: 0 },
  },
  even: {
    backgroundColor: 'white',
    '.triangleTop svg': { transform: 'scaleX(-1.01)' },
    '.triangleBot svg': { transform: 'scaleY(-1)' },
  },
  odd: {
    backgroundColor: colors.main.tile,

    '.triangleBot svg': { transform: 'scaleY(-1) scaleX(-1)' },
  },
}

const overflowOffOnDesktop = {
  [s.lg]: { overflowX: 'hidden' },
}

export default Tiles
