import React, { useState, useEffect } from 'react'

import Title from '../elements/Title'
import Subtitle from '../elements/Subtitle'

import VideoContainer from '../elements/VideoContainer'
import VideoList from '../elements/VideoList'

const { text } = require('../content/content')

const PortfolioSection = () => {
  const [videos, setVideos] = useState([])
  const [selectedVideo, setSelectedVideo] = useState(null)

  const handleSubmit = async () => {
    const response = await fetch(
      `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=5&playlistId=PLRfNaSHyKluwK_weR-pRyLMWaZmVdsehh&key=${process.env.GATSBY_KEY}`
    )
    const data = await response.json()
    console.log(data)
    setVideos(data.items)
    if (data.items?.[0]) setSelectedVideo(data.items?.[0])
  }

  const handleVideoSelect = video => setSelectedVideo(video)

  useEffect(() => {
    handleSubmit()
    // console.log('RERENDER')
  }, [])

  return (
    <div>
      <Title tag="h2">{text.portfolio.title}</Title>
      <Subtitle>{text.portfolio.field}</Subtitle>
      <VideoContainer
        video={selectedVideo}
        videos={videos}
        handleVideoSelect={handleVideoSelect}
      />
      {videos && (
        <VideoList
          videos={videos}
          selectedVideo={selectedVideo}
          handleVideoSelect={handleVideoSelect}
        />
      )}
    </div>
  )
}

export default PortfolioSection
